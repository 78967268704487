import React, { Component } from "react";
import { Helmet } from 'react-helmet';

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} - Webentwicklung, SEO und digitales Marketing </title>
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
