const BlogContent = [
    {
        images: '01',
        title: ' So erreichen Sie Ihre Zielgruppe online!',
        category: 'Digitales Marketing'
    },
    {
        images: '02',
        title: 'So wird Ihre Website gefunden! ',
        category: 'SEO-Optimierung'
    },

    {
        images: '03',
        title: 'So wird Ihre Marke im Internet sichtbar!',
        category: 'Online-Präsenz'
    },
    {
        images: '04',
        title: ' Getting tickets to the big show',
        category: 'Development'
    },
    {
        images: '01',
        title: 'A big ticket gone to be an interesting ',
        category: 'Management'
    },
    {
        images: '02',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
]

export default BlogContent;