import React ,{ Component }from "react";
import { FiCast , FiEdit, FiLayout, FiPenTool, FiTarget, FiThumbsUp } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiLayout />,
        title: 'Webentwicklung',
        description: 'Professionelle Website für alle Branchen, die auf Ihre spezifischen Anforderungen zugeschnitten sind. ⭐ Kreatives & professionelles Webdesign, Onlineshop erstellen, Webseiten-Pflege & Wartung.'
    },
    {
        icon: <FiTarget />,
        title: 'SEO Optimierung',
        description: 'Die Optimierung von Webseiten für eine bessere Auffindbarkeit in Suchmaschinen ist notwendig, empfehlenswert und effektiv im Online-Marketing. ⭐ On-Seite & Off-Seite, Keyword-Planer, SEO Audit.'
    },
    {
        icon: <FiThumbsUp />,
        title: 'Social Media Management',
        description: 'Optimierung Ihrer Social-Media-Präsenz, um Ihre Zielgruppe zu erreichen und das Engagement und die Reichweite zu erhöhen. ⭐ Beiträge verfassen, Kampagnen planen, Analysen.'
    },
    { 
        icon: <FiEdit />,
        title: 'SEO-Texten',
        description: 'Schnelle Lösung zum Erstellen von professionellen SEO-Texten für mehr Aufmerksamkeit. Ansprechende und informative Inhalte. ⭐ Website Artikel, Blog-Posts, Produktbeschreibung.'
    },
    {
        icon: <FiCast />,
        title: 'Digitales Marketing',
        description: 'Umfassende Strategie zur Verbesserung Ihrer Online-Präsenz, Erhöhung des Traffic und der Konversionsraten. ⭐ Pay-per-Click-Werbung, Content-Marketing, E-Mail-Marketing.'
    },
    { 
        icon: <FiPenTool />,
        title: 'Grafikdesign',
        description: 'Visuell ansprechende Gestaltung von Marken, um Ihre Botschaft auf kreative Weise zu kommunizieren. Entwerfen von ⭐ Logos, Flyern, Werbung, Anzeigen, Marketingmaterialien.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/#expertise">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
