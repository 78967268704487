import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";

class BlogDetails1 extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Seo Optimierung' />
                <HeaderThree headertransparent="header--transparent" colorblack="color--black" homeLink="/" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">SEO Optimierung <br /> Alles was du wissen musst</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Nov 30, 2021</li>
                                        <li><FiUser />Gena Dev.</li>
                                        <li><FiMessageCircle />25 Comments</li>
                                        <li><FiHeart />75 Like</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    {/*<p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>*/}
                                    {/*<p>In der heutigen digitalen Welt ist es entscheidend, Ihre Zielgruppe online zu erreichen und zu binden. In diesem Ratgeber stellen wir Ihnen die wichtigsten Strategien und Tools vor, die Ihnen dabei helfen, eine erfolgreiche digitale Marketingkampagne aufzusetzen und Ihre Zielgruppe anzusprechen. </p>*/}
                                        {/*<div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                        </div>*/}
                                        <p>In diesem Artikel werden die Grundlagen der Suchmaschinenoptimierung <strong>(SEO)</strong> erklärt. Dabei geht es um Techniken, mit denen man eine höhere Platzierung in den Suchergebnissen von Suchmaschinen wie Google erreichen kann. Wir zeigen, wie man <strong>Keyword-Recherche</strong> betreibt und wie man seine Website für Suchmaschinen optimiert. Auch der Aufbau von <strong>Backlinks</strong> und die Vermeidung von Fehlern im <strong>SEO-Bereich</strong> werden erläutert.
                                           Am Ende des Artikels werden Sie ein grundlegendes Verständnis von SEO haben und in der Lage sein, einige grundlegende Optimierungen auf Ihrer Website vorzunehmen, um Ihre Sichtbarkeit in den Suchmaschinen zu verbessern.</p>
                                        <blockquote className="rn-blog-quote">"Wenn es um SEO geht, ist es wichtig, dass Sie hart arbeiten, um an die Spitze zu kommen, aber noch wichtiger ist, dass Sie hart arbeiten, um dort zu bleiben." - Chris Thomas</blockquote>
                                        <p>Um gute SEO für dein Unternehmen zu machen, solltest du zunächst sicherstellen, dass deine Website technisch einwandfrei ist. Dazu gehört die Optimierung der Ladezeiten, die Verwendung von aussagekräftigen URLs und die Überprüfung, ob deine Website mobilfreundlich ist.</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-01.png" alt="BLog Images"/>
                                                <span>Seo Optimierung</span>
                                            </div>
                                            <div className="content">
                                                <p>Um mit der <strong>SEO-Optimierung</strong> deiner Website erfolgreich zu sein, solltest du diese Schritte ernst nehmen und strategisch angehen. Hier sind einige der wichtigsten Schritte, die du berücksichtigen solltest.</p>
                                                <h4 className="title">On-Page-Optimierung:</h4>
                                                <ul className="list-style">
                                                    <li>Zielgruppenanalyse</li>
                                                    <li>Keyword-Recherche</li>
                                                    <li>Technische Optimierung</li>
                                                    <li>Content-Erstellung</li>
                                                    <li>Interne Verlinkung</li>
                                                    <li>Bilder & Video Optimierung </li>
                                                </ul>
                                                <h4 className="title">Off-Page-Optimierung</h4>
                                                <ul className="list-style">
                                                    <li>Lokale Suche</li>
                                                    <li>Social Media Marketing</li>
                                                    <li>Influencer-Marketing</li>
                                                    <li>Analyse und Verbesserung</li>
                                                    <li>Kundenbewertungen</li>
                                                    <li>Backlinks</li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/*<blockquote className="rn-blog-quote">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.</blockquote>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start COntact Area 
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/about-9.jpg" contactTitle="Projekt im Sinn?" />
                </div>
            </div>
            {/* End COntact Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails1;