import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Vielen Dank! Ihre Nachricht wurde erfolgreich versendet. Ich werde mich bald bei Ihnen melden.</p>
    )
}

  function ContactThree(props) {
    const [result, setResult] = useState(false);
    const [inputName, setInputName] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [inputSubject, setInputSubject] = useState("");
    const [inputMessage, setInputMessage] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [inputErrors, setInputErrors] = useState({
      inputName: "",
      inputEmail: "",
      inputPhone: "",
      inputMessage: "",
      inputSubject: "",
    });
  
    const sendEmail = (e) => {
      e.preventDefault();
      if (validateForm()) {
        emailjs
          .sendForm(
            "service_v9cwd1f",
            "template_wfxy4je",
            e.target,
            "JMgnZX2gS9qTWsl4W"
          )
          .then(
            (result) => {
              setInputName("");
              setInputEmail("");
              setInputSubject("");
              setInputMessage("");
              setInputPhone("");
              setInputErrors({
                inputName: "",
                inputEmail: "",
                inputPhone: "",
                inputMessage: "",
                inputSubject: "",
              });
              setResult(true);
            },
            (error) => {
              setResult(false);
              setInputErrors({
                ...inputErrors,
                errorMessage:
                  "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
              });
            }
            
          );
          setTimeout(() => {
            setResult(false);
        }, 9000);
        
      }
    };
  
    const validateForm = () => {
      let formIsValid = true;
      const errors = {
        inputName: "",
        inputEmail: "",
        inputPhone: "",
        inputMessage: "",
        inputSubject: "",
      };
  
      if (!inputName) {
        errors.inputName = "Bitte geben Sie Ihren vollständigen Namen!";
        formIsValid = false;
      }
  
      if (!inputPhone || isNaN(inputPhone)) {
        errors.inputPhone = "Bitte geben Sie eine gültige Telefonnummer!";
        formIsValid = false;
      }
  
      if (!inputEmail || !/\S+@\S+\.\S+/.test(inputEmail)) {
        errors.inputEmail = "Bitte geben Sie eine gültige E-Mail-Adresse!";
        formIsValid = false;
      }
  
      if (!inputSubject) {
        errors.inputSubject = "Bitte schreiben Sie Ihren Betreff!";
        formIsValid = false;
      }
  
      if (!inputMessage) {
        errors.inputMessage =
          "Bitte schreiben Sie Ihre Nachricht oder Ihre Fragen!";
        formIsValid = false;
      }
  
      setInputErrors(errors);
  
      return formIsValid;
    };
    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">{props.contactTitle}</h2>
                            <p className="description">
                                Lassen Sie uns darüber sprechen und gemeinsam Ihre Ideen
                                Wirklichkeit werden lassen. Kontaktieren Sie mich bitte über das
                                untenstehende Formular!
                                {/*<br />
                                <strong>
                                    Beachtung!{' '}
                                </strong>
                                <span style={{ color: '#D22B2B' }}>
                                    Keine neuen Anfragen bis zum 25. März möglich!
                                </span>{' '} */}
                            </p>
                        </div>
                        <div className="form-wrapper">
                            <form action="" onSubmit={sendEmail}>
                            {inputErrors.inputName && <div style={{ color: '#D22B2B' }}>{inputErrors.inputName}</div>}
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="inputName"
                                        id="item01"
                                        value={inputName}
                                        onChange={(e) => setInputName(e.target.value)}
                                        placeholder="Vollständiger Name *"
                                    />
                                </label>
                                {inputErrors.inputPhone && <div style={{ color: '#D22B2B' }}>{inputErrors.inputPhone}</div>}
                                <label htmlFor="item05">
                                    <input
                                        type="text"
                                        name="phone"
                                        id="item05"
                                        value={inputPhone}
                                        onChange={(e) => setInputPhone(e.target.value)}
                                        placeholder="Telefonnummer *"
                                    />
                                </label>
                                {inputErrors.inputEmail && <div style={{ color: '#D22B2B' }}>{inputErrors.inputEmail}</div>}
                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="inputEmail"
                                        id="item02"
                                        value={inputEmail}
                                        onChange={(e) => setInputEmail(e.target.value)}
                                        placeholder="E-Mail Adresse *"
                                    />
                                </label>
                                {inputErrors.inputSubject && <div style={{ color: '#D22B2B' }}>{inputErrors.inputSubject}</div>}
                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        value={inputSubject}
                                        onChange={(e) => setInputSubject(e.target.value)}
                                        placeholder="Betreff *"
                                    />
                                </label>
                                {inputErrors.inputMessage && <div style={{ color: '#D22B2B' }}>{inputErrors.inputMessage}</div>}
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        id="item04"
                                        name="message"
                                        value={inputMessage}
                                        onChange={(e) => setInputMessage(e.target.value)}
                                        placeholder="Nachricht..."
                                    />
                                </label>
                                {/*    {Object.keys(inputErrors).map((key) => {
                                    if (inputErrors[key]) {
                                        return (
                                            <div key={key} style={{ color: '#D22B2B' }}>
                                                {inputErrors[key]}
                                            </div>
                                        );
                                    }
                                    return null;
                                })*/}

                                <button
                                    className="rn-button-style--2 btn-solid"
                                    type="submit"
                                    value="submit"
                                    name="submit"
                                    id="mc-embedded-subscribe"
                                >
                                    Jetzt senden
                                </button>
                                <div className="rn-form-group">
                                <div className="rn-form-group">
                                        {result ? <Result /> : null}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src={`${props.contactImages}`} alt="GenWerb" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default ContactThree;