import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";

class BlogDetails0 extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Digital Marketing' />
                <HeaderThree headertransparent="header--transparent" colorblack="color--black" homeLink="/" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Digitales Marketing für Unternehmen</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Aug 23, 2019</li>
                                        <li><FiUser />Gena Dev.</li>
                                        <li><FiMessageCircle />17 Comments</li>
                                        <li><FiHeart />52 Like</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    {/*<p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>*/}
                                    {/*<p>In der heutigen digitalen Welt ist es entscheidend, Ihre Zielgruppe online zu erreichen und zu binden. In diesem Ratgeber stellen wir Ihnen die wichtigsten Strategien und Tools vor, die Ihnen dabei helfen, eine erfolgreiche digitale Marketingkampagne aufzusetzen und Ihre Zielgruppe anzusprechen. </p>*/}
                                        {/*<div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                        </div>*/}
                                        <p>Digitales Marketing ist heutzutage ein wichtiger Bestandteil jeder <strong>Marketingstrategie</strong>, besonders in Deutschland, einem der führenden Länder in Europa, wenn es um Technologie und Innovation geht. Ob Sie ein kleines oder mittelständisches Unternehmen sind, <strong>digitales Marketing</strong> bietet Ihnen die Möglichkeit, Ihre <strong>Zielgruppe</strong> auf effektive und kosteneffiziente Weise zu erreichen. In diesem Artikel werden wir Ihnen einige einfach umzusetzende Schritte vorstellen, um Ihre Ziele mit digitalem Marketing zu erreichen.</p>
                                        <blockquote className="rn-blog-quote">"Digitales Marketing ist wie eine Welle. Entweder man lernt, auf ihr zu surfen, oder man geht unter." - Tobias Kollmann, Professor für E-Business und E-Entrepreneurship</blockquote>
                                        <p><strong>Erstellen Sie eine digitale Marketingstrategie: </strong>
                                           Bevor Sie mit Ihrer digitalen Marketingkampagne beginnen, müssen Sie eine Strategie entwickeln, die auf Ihre Ziele und Zielgruppe abgestimmt ist. Beginnen Sie damit, Ihre Zielgruppe zu definieren und Ihre Ziele zu identifizieren. Dann sollten Sie Ihre Online-Präsenz analysieren, Ihre Wettbewerber bewerten und Ihre Marketingbudgets festlegen.</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-01.png" alt="BLog Images"/>
                                                <span>Digital Marketing</span>
                                            </div>
                                            <div className="content">
                                                <p>Mit einer klugen Strategie und den richtigen Tools können Sie Ihre <strong>Online-Präsenz</strong> verbessern, Ihre Marke aufbauen und den Umsatz steigern. Verwenden Sie diese einfachen Anweisungen, um Ihre digitale Marketingstrategie zu verbessern und Ihre Ziele zu erreichen.</p>
                                                <h4 className="title">Kanäle</h4>
                                                <ul className="list-style">
                                                    <li>Suchmaschinenoptimierung (SEO)</li>
                                                    <li>Social-Media-Marketing (SMM)</li>
                                                    <li>Pay-per-Click-Werbung (PPC)</li>
                                                    <li>Conversion-Rate-Optimierung (CRO)</li>
                                                    <li>E-Mail-Marketing</li>
                                                </ul>
                                                <h4 className="title">Strategien</h4>
                                                <ul className="list-style">
                                                    <li>Affiliate-Marketing</li>
                                                    <li>Mobiles Marketing</li>
                                                    <li>Video-Marketing</li>
                                                    <li>Content Marketing</li>
                                                    <li>Rufmanagement</li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/*<blockquote className="rn-blog-quote">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.</blockquote>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start COntact Area 
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/about-9.jpg" contactTitle="Projekt im Sinn?" />
                </div>
            </div>
            {/* End COntact Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails0;