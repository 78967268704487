import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";

class impressum1 extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Impressum'>
          <meta name="robots" content="noindex" />
        </PageHelmet>
        <HeaderThree headertransparent="header--transparent" colorblack="color--black" homeLink="/" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Impressum</h2>
                                    {/*<ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Nov 30, 2021</li>
                                        <li><FiUser />Gena Dev.</li>
                                        <li><FiMessageCircle />25 Comments</li>
                                        <li><FiHeart />75 Like</li>
                                    </ul>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Impressum */}
<div className="rn-blog-details pt--110 pb--70 bg_color--1">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="inner-wrapper">
          <div className="inner">
            {/*<h2 className="title theme-gradient">
              Impressum
              </h2>
                  <p>
                    Hier können Sie alle notwendigen rechtlichen Informationen
                    über Ihre Website oder Ihren Blog bereitstellen. Dies umfasst
                    Ihre Kontaktdaten, Adresse, Firmenname, Umsatzsteuer-Identifikationsnummer
                    und andere gesetzliche Anforderungen je nach Standort.
                  </p> */}
                  <h3 className="title-2">
                    Angaben gemäß § 5 TMG:
                  </h3>
                  <p>
                    Gentjana Hysa<br />
                    Parchwitzer Straße 6<br />
                    82256 Fürstenfeldbruck<br />
                    Deutschland<br />
                  </p>
                  <h3 className="title-2">
                    Kontakt:
                  </h3>
                  <p>
                    Telefon: 0177/4482554<br />
                    E-Mail: info(@)genwerb.de<br />
                  </p>
                  <h3 className="title-2">
                    Steuernummer:
                  </h3>
                  <p>
                    117/231/91825<br />
                  </p>
                  <h3 className="title-2">
                    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                  </h3>
                  <p>
                    Gentjana Hysa<br />
                    Parchwitzer Straße 6<br />
                    82256 Fürstenfeldbruck<br />
                    Deutschland<br />
                  </p>
                  <h3 className="title-2">
                  Haftung für Inhalte:
                  </h3>
                  <p>
                  Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
 
 
 Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br />
                  </p>
                  <h3 className="title-2">
                  Urheberrecht:
                  </h3>
                  <p>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
​
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br />
                  </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Impressum */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default impressum1;