import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";

class BlogDetails2 extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Ihre Marke'>
                    <meta name="robots" content="index" />
                </PageHelmet>
                <HeaderThree headertransparent="header--transparent" colorblack="color--black" homeLink="/" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Die Bausteine einer <br /> erfolgreichen Online-Präsenz</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Dez 05, 2022</li>
                                        <li><FiUser />Gena Dev.</li>
                                        <li><FiMessageCircle />8 Comments</li>
                                        <li><FiHeart />55 Like</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                    {/*<p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>*/}
                                    {/*<p>In der heutigen digitalen Welt ist es entscheidend, Ihre Zielgruppe online zu erreichen und zu binden. In diesem Ratgeber stellen wir Ihnen die wichtigsten Strategien und Tools vor, die Ihnen dabei helfen, eine erfolgreiche digitale Marketingkampagne aufzusetzen und Ihre Zielgruppe anzusprechen. </p>*/}
                                        {/*<div className="thumbnail">
                                            <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                        </div>*/}
                                        <p>In der heutigen digitalen Welt ist es für Unternehmen unerlässlich, online sichtbar zu sein. Eine starke Online-Präsenz bedeutet, dass Ihre Marke auf verschiedenen digitalen Kanälen vertreten ist, einschließlich Ihrer Website, sozialer Medien, Online-Verzeichnisse und mehr. Mit einer starken Online-Präsenz können Sie Ihre Zielgruppe erreichen, Beziehungen aufbauen und Ihre Marke bekannt machen.</p>
                                        <blockquote className="rn-blog-quote"> "Deine Marke ist das, was die Leute über dich sagen, wenn du nicht im Raum bist." - Jeff Bezos</blockquote>
                                        <p>Schließlich ist es wichtig, eine konsistente Markenbotschaft und Stimme auf allen digitalen Kanälen aufrechtzuerhalten. Dies kann durch die Verwendung eines einheitlichen Designs, einer konsistenten Sprache und einer klaren Positionierung Ihrer Marke erreicht werden.</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-01.png" alt="BLog Images"/>
                                                <span>Erfolgreichen Online-Präsenz</span>
                                            </div>
                                            <div className="content">
                                                <p>Hier sind die 10 Schritte zur Erstellung einer starken <strong>Online-Präsenz</strong></p>
                                                <ul className="list-style">
                                                    <li>Erstellen Sie eine benutzerfreundliche und optimierte Website.</li>
                                                    <li>Nutzen Sie soziale Medien, um eine Community aufzubauen und zu interagieren.</li>
                                                    <li>Melden Sie Ihr Unternehmen bei Online-Verzeichnissen und Branchenverzeichnissen an.</li>
                                                    <li>Verfolgen Sie Ihre Online-Reputation und reagieren Sie auf Kundenbewertungen.</li>
                                                    <li>Optimieren Sie Ihre Website für Suchmaschinen.</li>
                                                    <li>Erstellen Sie einen Blog und veröffentlichen Sie regelmäßig relevante und informative Inhalte.</li>
                                                    <li>Verwenden Sie visuelle Elemente wie Bilder und Videos, um Ihre Inhalte interessanter und ansprechender zu gestalten.</li>
                                                    <li>Bieten Sie ein hervorragendes Kundenerlebnis auf Ihrer Website, klare Informationen und eine schnelle Ladezeit bieten.</li>
                                                    <li>Nutzen Sie E-Mail-Marketing, um Ihre Kunden über Angebote, Neuigkeiten und andere relevante Informationen zu informieren.</li>
                                                    <li>Verfolgen Sie Ihre Online-Analytik, und passen Sie Ihre Strategie entsprechend an.</li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/*<blockquote className="rn-blog-quote">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.</blockquote>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start COntact Area 
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/about-9.jpg" contactTitle="Projekt im Sinn?" />
                </div>
            </div>
            {/* End COntact Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails2;