// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout


// Dark Home Layout 
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import BlogDetails0 from "./elements/BlogDetails-0";
import BlogDetails1 from "./elements/BlogDetails-1";
import BlogDetails2 from "./elements/BlogDetails-2";
import Impressum    from "./elements/Impressum"
import impressum1 from './elements/impressum-1';
import error404 from "./elements/error404";



import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkPortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/artikel-0`} component={BlogDetails0}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/artikel-1`} component={BlogDetails1}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/artikel-2`} component={BlogDetails2}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/impressum-genwerb`} component={Impressum}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/datenschutz`} component={impressum1}/>


                        {/* Blocks Elements  */}



                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();