import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-1',
        /*category: 'and',
        title: 'Getting tickets to the big show'*/
    },
    {
        image: 'image-2',
        /*category: 'Development',
        title: 'Getting tickets to the big show'*/
    },
    {
        image: 'image-3',
        /*category: 'Development',
        title: 'Getting tickets to the big show'*/
    },
    {
        image: 'image-4',
        /*category: 'and',
        title: 'Getting tickets to the big show'*/
    },
    {
        image: 'image-5',
        /*category: 'Development',
        title: 'Getting tickets to the big show'*/
    },
    {
        image: 'image-6',
        /*category: 'Development',
        title: 'Getting tickets to the big show'*/
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
    {list.map((value , index) => (
        <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
                <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                </div>
                <div className="content">
                    <div className="inner">
                        <p>{value.category}</p>
                        <div className="portfolio-button">
                            <button className="rn-btn" onClick={() => {}}>Gena Dev.</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ))}
</React.Fragment>

        )
    }
}
export default PortfolioList;