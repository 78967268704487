import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Fähigkeiten",
        tab2 = "Erfahrung",
        tab3 = "Ausbildung & Zertifizierung";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/#erfahrungen">Webentwicklung <span> - Neueste Technologien</span></a>
                                                    Modernes Web-Development für eine effektive Online-Präsenz.
                                                </li>
                                                <li>
                                                    <a href="/#erfahrungen">Grafikdesign<span> - Kreative Tools;  UI/UX</span></a>
                                                    Kreative und ansprechende Gestaltung für eine professionelle Ästhetik.
                                                </li>
                                                <li>
                                                    <a href="/#erfahrungen">SEO-Optimierung <span> - Aktuelle Methoden</span></a>
                                                    Suchmaschinenoptimierung für eine bessere Sichtbarkeit und Reichweite im Internet.
                                                </li>
                                                <li>
                                                    <a href="/#erfahrungen">Content Writing<span> - Korrekturlesen</span></a>
                                                    Hochwertiges und SEO Content Writing für eine authentische Markenpräsenz.
                                                </li>
                                                <li>
                                                    <a href="/#erfahrungen">Social Media Management <span> - Professionelle Verwaltung</span></a>
                                                    Verwaltung von Social-Media-Kanälen für eine erfolgreiche Online-Präsenz.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                           <li>
                                                   <a href="/#erfahrungen">Team-Assistentin <span>- Network Movie Film und Fernsehproduktion GmbH</span></a> Aktuel
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">Geographisches Informationssystem GIS <span>- Uniqa Group Austria</span></a> Projekt
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">Online-Marktplatz für lokale Handwerker <span>- Handwerkermarkt GmbH</span></a> Projekt
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">Betriebsmanagement und Verwaltung <span>- Beste Geschäftsideen</span></a> Auszeichnung
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">Social Media Präsenz <span>- Start-UP Unternehmen</span></a> Projekt
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/#erfahrungen">Wirtschaftsinformatik<span> - Modellierung von Geschäftsprozessene</span></a> Programmierung, Systemanalyse und Softwareentwurf
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">Betriebswirtschaft<span> - Start-Ups und Werbung</span></a> Planung, Koordinierung und Kontrolle von Projekten.
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">Betriebsmanagement<span> - Marketing und Management</span></a> Informationstechnik
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">Social Media Management<span> - Marketing Strategien</span></a> Managerin
                                               </li>
                                               <li>
                                                   <a href="/#erfahrungen">SEO Content Writer<span> - Marketing- und Werbeabteilungen </span></a> SEO-Texterin / Werbetexterin
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;